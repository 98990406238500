.video-section{
    padding: 8.8rem 0rem;
    background-color: var(--gray-color);
    h2{
        font-size: var(--fz-h4);
        text-transform: uppercase;
        color: var(--fourth-color);
    }
}
// Small devices 
@media (min-width: 576px) {

}

// Medium devices 
@media (min-width: 768px) {

}

// Large devices
@media (min-width: 992px) {
    .video-section{
        h2{
            font-size: var(--fz-h1);
        }
    }
}

// Extra large devices
@media (min-width: 1200px) {

}

// Extra Extra large devices 
@media (min-width: 1400px) {

}