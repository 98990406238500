.video-section {
  padding: 8.8rem 0rem;
  background-color: var(--gray-color);
}
.video-section h2 {
  font-size: var(--fz-h4);
  text-transform: uppercase;
  color: var(--fourth-color);
}

@media (min-width: 992px) {
  .video-section h2 {
    font-size: var(--fz-h1);
  }
}